.img {
    border-radius: 1rem;
    height: 10rem;
    object-fit: cover;
    object-position: top;
}

.imgPlaceholder {
    border-radius: 0.5rem;
    object-fit: cover;
}

.contentContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
}

.upperContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.mobP2 {
    padding: 2rem;
}

.diplomasContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: white;
    border-radius: 15px;
    padding: 10px;
}

.input {
    padding-left: 10px;
}

.tabPanel {
    width: 100%;
    display: grid;
    grid-template-columns: 4fr 4fr 2fr 0fr 0fr;
    gap: 36px;
    align-items: flex-start;
    flex-direction: row;
    border-radius: 15px;
    padding: 10px;
    padding-left: 20px;
}

.mobP15 {
    padding: 1.5rem;
}

@media only screen and (max-width: 768px) {
    .mobP2 {
        padding: .2rem;
    }

    .mobP15 {
        padding: 0;
    }

    .upperContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .contentContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-top: 1rem;
    }

    .diplomasContainer {
        width: 95%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background-color: white;
        border-radius: 15px;
        padding: 10px;
    }

    .input {
        width: 100%;
        padding-left: 10px;
    }

    .tabPanel {
        width: 95%;
        display: grid;
        grid-template-columns: 4fr 4fr 2fr 0fr 0fr;
        gap: 36px;
        align-items: flex-start;
        flex-direction: row;
        border-radius: 15px;
        padding: 10px;
        padding-left: 20px;
    }
}

@media only screen and (max-width: 768px) {

    .contentContainer {
        gap: 0 !important;
    }

    .mobMt1 {
        margin-top: 1rem !important;
    }

    .mobMt2 {
        margin-top: 2rem !important;
    }

    .mobWrap {
        display: flex !important;
        flex-wrap: wrap !important;
    }

    .cardImgContainer {
        position: absolute !important;
        bottom: -2.5rem !important;
        left: 2rem !important;
        border-radius: .5rem !important;
        padding: .2rem !important;
    }

    .cardImg {
        width: 5rem !important;
        border-radius: .5rem !important;
    }

    .headerImg {
        height: 12rem !important;
    }


    .accessModal {
        width: 95% !important;
    }

    .social:nth-of-type(-n + 5) {
        display: none !important;
    }

    .textMd {
        font-size: 1.2rem !important;
    }

    .gap1 {
        gap: 1rem !important;
    }

    .textSm {
        font-size: 1rem !important;
    }

    .textXs {
        font-size: .8rem !important;
    }

    .nameText {
        font-size: 1.25rem !important;
    }
}

.mainContainer {

    padding: 0 3%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

@media only screen and (max-width: 1080px) {
    .accessModal {
        width: 95%;
    }

    .mainContainer {
        padding: 0 3%;
    }
}

@media only screen and (max-width: 768px) {
    .diplomaImg {
        width: 100% !important;
        padding: 1rem !important;
    }

    .diplomasContainer {
        padding: 0 !important;
    }

    .accessModal {
        width: 95%;
    }

    .mobText {
        font-size: 1rem !important;
    }

    .mobMb1 {
        margin-bottom: 1rem !important;
    }

    .mobTextL {
        font-size: 1.5rem !important;
    }

    .mobTextSm {
        font-size: .8rem !important;
    }

    .mobPl2 {
        padding-left: 2rem;
    }

    .mobPl1 {
        padding-left: 1rem;
    }

    .diplomaItem {
        width: 100%;
        display: flex;
        flex-direction: row;
    }
}