.active {

	border: black 1px;
}

.link {
	display: block;
	color: gray;
}

.link:hover {
	cursor: pointer;
	color: black;
	border: black 5px !important;
}

.contentLeftContainer {
	width: 32%;
	display: flex;
	flex-direction: column;
}

.contentLeft {
	display: flex;
	margin-top: 2rem;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	justify-content: space-between;
}

.contentRightContainer {
	width: 45%;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	padding-top: 1.5rem;
}

.mt02 {
	margin-top: .2rem;
}

@media only screen and (max-width: 768px) {

	.contentLeftContainer {
		width: 85%;
		display: flex;
		flex-direction: column;
	}

	/* .contentLeft {
		position: absolute;
		bottom: -10rem;
		left: 0;
		display: flex;
		margin-top: 1rem;
		background-color: #0A66C2 !important;
		justify-content: space-between;
	} */



	.iconMobile {
		margin-left: .8rem;
		filter: invert(94%) sepia(0%) saturate(24%) hue-rotate(24deg) brightness(166%) contrast(108%);
	}

	.contentRightContainer {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 1rem;
		padding: 1.5rem 2rem 0;
	}

	.textMd {
		font-size: 1rem !important;
	}

	.textSm {
		font-size: .8rem !important;
		font-weight: 400 !important;
	}

	.textXs {
		font-size: .8rem !important;
	}

	.iconSm {
		width: 2rem !important;
		margin: 0 !important;
	}

	.mobMt05 {
		margin-top: .5rem !important;
	}

	.mobMt0 {
		margin-top: 0 !important;
	}

	.mobMt1 {
		margin-top: 1rem !important;
	}

	.mobMt2 {
		margin-top: 2rem !important;
	}

	.mobMb1 {
		margin-bottom: 1rem !important;
	}

	.mobPb1 {
		padding-bottom: .5rem !important;
	}

	.mobMb1 {
		margin-bottom: 2rem !important;
	}

	.infoContainer {
		margin-top: 1rem !important;
		display: flex !important;
		padding: 0 1rem !important;
		gap: 0 !important;
		justify-content: space-between !important;
	}
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Apply the animation to the element */
.rotatingElement {
    /* Set the duration and specify that it should repeat infinitely */
    animation: rotate 5s linear infinite;
}