.contentText {
	width: 60%;
}

.mainContainer {
	padding: 0 9%;
	display: flex;
	flex-direction: column;
	gap: 8rem;
}

.contactUsContainer {
	display: flex;
	justify-content: space-evenly;
	width: 100%;
	padding: 2rem 10% 0 0;
	gap: 6rem;
}

.sliderMobile {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 2rem;
	-ms-overflow-style: none !important;
	/* IE and Edge */
	scrollbar-width: none !important;
	/* Firefox */
}

.sliderMobile>img {
	height: 4rem;
	object-fit: cover;
}

.sliderMobile::-webkit-scrollbar {
	height: 0;
}

.container {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	gap: 3%;
}

.item {
	width: 48.5%;
	display: flex;
	text-align: left;
}

.cardItem {
    background-color: #F8F8F8;
    padding: 2rem;
    min-width: 30vw;
    width: 100vw;
    height: 100%;
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.footer {
	width: 100%;
	display: flex;
	padding: 0 8% 2rem 0;
	justify-content: space-between;
}

.mobTextL,
.mobTextMd,
.mobTextSm {
	white-space: pre-line;
}

.btn {
	display: flex !important;
	justify-content: center;
	padding: 1rem;
}


.btnIcon {
	filter: brightness(250%) contrast(101%);
	width: 3.5rem !important;
	height: 3.5rem !important;
	margin-left: .5rem;
}

@media only screen and (max-width: 1200px) {
	.applogo {
		width: inherit !important;
	}

	.cardItem {
		min-width: 40vw;
	}

	.sliderMobile>img {
		height: 3rem;
		object-fit: cover;
	}

	.mainContainer {
		padding: 0 3%;
		gap: 4rem;
	}
}


@media only screen and (max-width: 768px) {
	.applogo {
		width: 10rem !important;
	}

	.btnIcon {
		width: 2rem !important;
		height: 2rem !important;
	}

	/*.btn {*/
	/*    padding: 0 !important;*/
	/*}*/
	.btnIcon {
		margin: 0 !important;
		padding: 0 !important;
	}

	.mobTextNone {
		font-size: 0 !important;
	}

	.mobTextL,
	.mobTextMd,
	.mobTextSm {
		white-space: normal !important;
	}

	.cardItem {
		min-width: 80vw;
	}

	.contactUsContainer {
		display: flex;
		flex-direction: column;
		padding: 0 5%;
		gap: 1rem;
	}

	.item {
		width: 100% !important;
	}

	.item:first-of-type {
		margin-bottom: 2rem;
	}

	.contentText {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 1.5rem;
		margin-top: 0;
	}


	.sliderMobile>img {
		height: 2rem;
		object-fit: cover;
	}

	.sliderMobile {
		overflow: scroll;
		height: 2rem;
		gap: 1.75rem;
		flex-wrap: nowrap;
		-ms-overflow-style: none !important;
		/* IE and Edge */
		scrollbar-width: none !important;
		/* Firefox */
	}

	.sliderMobile ::-webkit-scrollbar,
	::-webkit-scrollbar-thumb {
		display: none !important;
		width: .5em !important;
	}

	.dMobileNone {
		display: none;

	}

	.mobTextL {
		font-size: 1.75rem !important;
	}

	.mobTextMd {
		font-size: 1rem !important;
	}

	.mobTextSm {
		font-size: .8rem !important;
	}


}
