.contentText {
	width: 60%;
}

.contactUsContainer {
	display: flex;
	justify-content: space-evenly;
	width: 100%;
	padding: 2rem 10% 0 4rem;
	padding-top: 6rem;
	background-color: white;
}

.item {
	width: 48.5%;
	display: flex;
	text-align: left;
}

.mobTextL,
.mobTextMd,
.mobTextSm {
	white-space: pre-line;
}



.hero {
	display: flex;
	justify-content: center;
	gap: 2rem;
	height: 25rem;
	width: 100%;
}

.img {
	border-radius: 2rem;
	object-fit: cover;
}

.imgHeroLeft {
	width: 60% !important;
	object-position: left;
}

.imgHeroRight {
	height: 100%;
	width: 40%;
}

.whoAreWeContainer {
	display: flex;
	gap: 2rem;
	margin-top: 4rem;
	width: 100%;
	justify-content: space-between;
}

.textLg {
	font-size: 2rem !important;
	font-weight: 600 !important;
	color: #2D2D2D !important;
}

.textMd {
	font-size: 1rem !important;
	color: #4D4D4D !important;
	white-space: pre-line;
}

.descriptionContainer {
	display: flex;
	flex-direction: row;
	gap: 2rem;
	margin-top: 7rem;
	width: 100%;
	justify-content: space-between;
}

.description {
	display: flex;
	flex-direction: column;
	width: 50%;
	justify-content: center;
}

.flexColumn {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.mt1 {
	margin-top: 1rem;
}

.textBlue {
	color: #3B82F6 !important;
}

.w100 {
	width: 100%;
}

.descriptionImgRight {
	display: flex;
	width: 50%;
	flex-direction: column;
	justify-content: center;
}

.goalContainer {
	justify-content: center;
	flex-direction: column;
	gap: 1rem;
	margin-top: 9rem;
	width: 100%;
	display: flex;
}

.container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 2rem 12rem;
}

.textGray {
	color: #818181 !important;
}

.goalItemsContainer {
	margin-top: 1.5rem;
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 2rem;
}

.goalItem {
	display: flex;
	justify-content: center;
}

@media only screen and (max-width: 768px) {
	.contentText {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 1.5rem;
		margin-top: 0;
	}

	.imgHeroLeft {
		height: 50%;
		width: 100%;
	}

	.imgHeroRight {
		height: 30%;
	}


	.container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 2rem 4% 3rem 4%;
		width: 100%;
	}

	.whoAreWeContainer {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		margin-top: 3rem;
		width: 100%;
		justify-content: space-between;
	}

	.hero {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 1rem;
		width: 100%;
	}

	.descriptionContainer {
		display: flex;
		flex-direction: column;
		gap: 2rem;
		margin-top: 6rem;
		width: 100%;
		justify-content: space-between;
	}

	.description {
		display: flex;
		flex-direction: column;
		width: 100%;
		justify-content: center;
	}

	.img {
		border-radius: 2rem;
		width: 100% !important;
	}

	.descriptionImgRight {
		display: flex;
		flex-direction: row;
		justify-content: center;
		width: inherit !important;
	}

	.goalItemsContainer {
		margin-top: 2rem;
		display: flex;
		flex-direction: column;
		gap: 2rem;
		width: 100%;
	}

	.item {
		width: 100% !important;
	}

	.item:first-of-type {
		margin-bottom: 2rem;
	}

	.contactUsContainer {
		display: flex;
		flex-direction: column;
		padding: 2rem 5%;
	}

	.mobTextL,
	.mobTextMd,
	.mobTextSm {
		white-space: normal !important;
	}

	.mobTextL {
		font-size: 1.75rem !important;
	}

	.mobTextMd {
		font-size: 1rem !important;
	}

	.mobTextSm {
		font-size: .8rem !important;
	}

	.textLg {
		font-size: 1.75rem !important;
		font-weight: 600 !important;
		color: #2D2D2D !important;
	}

	.textMd {
		font-size: 1rem !important;
		color: #4D4D4D !important;
		white-space: pre-line;
	}

	.btn {
		width: 50%;
	}

	.sliderMobile {
		overflow-x: scroll;
		width: 100%;
		justify-content: start !important;
		padding: 0 1rem !important;
		-ms-overflow-style: none !important;
		/* IE and Edge */
		scrollbar-width: none !important;
		/* Firefox */
	}

	.sliderMobile ::-webkit-scrollbar,
	::-webkit-scrollbar-thumb {
		display: none !important;
		width: .5em !important;
	}

	.dMobileNone {
		display: none;

	}


}