.chart-toggle-button {
    padding: 10px;
    margin: 5px;
    border: none;
    background-color: #f0f0f0;
    color: #333;
    cursor: pointer;
}

.chart-toggle-button.active {
    background-color: #3498db;
    color: white;
}

.barchart:hover {
    background-image: url("Bar.png");
    background-size: cover;
    transition: opacity 0.3s ease;
}

.doughnut:hover {
    background-image: url("Doughnut.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 0.3;
}

.Bars {
    width: 50%;
}

.Doughnuts {
    width: 26%;
}

.Graphs {
    width: 70%;
    justify-content: space-around;
    display: flex;
    flex: 1;
}

.butt {
    width: 30%;
}

.custom-tooltip {
    background-color: white;
    border: 1px solid #ccc; /* Add a border with a light gray color */
    border-radius: 10px; /* Add rounded borders */
    padding: 10px; /* Add some padding to the content inside the tooltip */
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}

.tooltip-container p {
    margin: 0; /* Remove any default margin for paragraphs */
    font-size: 14px; /* Set the font size */
    color: #333; /* Set the text color */
}
