.mobTextL, .mobTextMd, .mobTextSm {
    white-space: pre-line;
}

.btn {
    display: flex !important;
    justify-content: center;
    padding: 1rem;
}

.mainContainer {
    padding: 0 1%;
    display: flex;
    flex-direction: column;
}

.cardItem {
    background-color: #3B82F6;
    padding: 1rem;
    width: auto;
    max-width: 45%;
    height: auto;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.btnIcon {
    filter: brightness(250%) contrast(101%);
    width: 3.5rem !important;
    height: 3.5rem !important;
    margin-left: .5rem;
}

@media only screen and (max-width: 768px) {
    .btnIcon {
        width: 2rem !important;
        height: 2rem !important;
    }

    .btnIcon {
        margin: 0 !important;
        padding: 0 !important;
    }

    .mobTextNone {
        font-size: 0 !important;
    }

    .mobTextL, .mobTextMd, .mobTextSm {
        white-space: normal !important;
    }

    .mobTextL {
        font-size: 1.75rem !important;
    }

    .mobTextMd {
        font-size: 1rem !important;
    }

    .mobTextSm {
        font-size: .8rem !important;
    }
}