.mainContainer {
    padding: 0 1%;
    display: flex;
    flex-direction: column;
}

.diplomaImg {
    z-index: 0 !important;
    border-radius: 1rem 1rem 0 0;
}

.diplomasContainer {
    border-radius: 2rem;
    border: 1px #4D4D4D;
}

.btnIcon {
	filter: brightness(250%) contrast(101%);
	margin-left: .5rem;
}

.item {
	width: 48.5%;
	display: flex;
	text-align: left;
}