.container {
    top: 0;
    width: 100%;
    height: 100vh;
    /*background: url("/src/assets/auth/background.jpg") no-repeat center center fixed;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    justify-content: space-between;
}

.navbar {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    display: flex;
    padding: 3rem 3rem 0 7.5rem;
    justify-content: space-between;
}

.footerAuth {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    cursor: pointer;
    color: #818181;
    display: flex;
    padding: 0 8% 2rem 8%;
    justify-content: space-between;
}

.brand {
    cursor: pointer;
    height: 2.5rem;
}

.navItemSm {
    display: none;
}

.containerRight {
    background: url("/src/assets/auth/background.png") no-repeat right;
    width: 50vw;
    border-radius: 0 0 0 10rem;
    background-size: cover;
}

.contentRight {
    width: 50vw;
    display: flex;
}

.contentLeft {
    width: 50vw;
    padding: 0 10%;
    /*padding-right: 6rem;*/
    display: flex;
}

.mobTextL,
.mobTextMd,
.mobTextSm {
    white-space: pre-line;
}


.textLg {
    font-size: 2rem !important;
    font-weight: 600 !important;
}

.textMd {
    font-size: .85rem !important;
    white-space: pre-line;
}

.mobTextSm {
    font-size: .8rem !important;
}

@media only screen and (max-width: 768px) {
    .navbar {
        justify-content: center;
        width: 90vw;
        padding: .5rem;
        border-radius: 2.5rem;
        margin: 1rem;
        background-color: rgba(255, 255, 255, 0.20);
;
    }
    .brand {
        height: 1.7rem;
    }
    .navItemLg {
        display: none;
    }

    .navItemSm {
        display: flex;
    }

    .container {
        margin-top: -3rem !important;
    }

    /* For mobile phones: */
    .contentRight {
        display: none !important;
    }

    .containerRight {
        display: none !important;
    }

    .contentLeft {
        background: url("/src/assets/auth/background_mobile.jpg") center !important;
        width: 100vw;
        padding: 0 !important;

        background-size: contain;
    }

    .footerAuth {
        color: white !important;
        padding: 0 0 1rem 2rem !important;
    }

    .footerRightItemsMob {
        display: none !important;
    }

    .navbar {
        padding: 1rem 1rem;
    }

    .mobTextL,
    .mobTextMd,
    .mobTextSm {
        white-space: normal !important;
    }

    .mobTextL {
        font-size: 1.75rem !important;
    }

    .mobTextMd {
        font-size: 1rem !important;
    }

    .mobTextSm {
        font-size: .8rem !important;
    }

    .textLg {
        font-size: 1.5rem !important;
        font-weight: 600 !important;
    }

    .textMd {
        font-size: .85rem !important;
        white-space: pre-line;
    }

    .mobDNone {
        display: none !important;
    }

    .preLine {
        white-space: pre-line !important;
    }
}
