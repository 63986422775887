.img {
	border-radius: 2rem;
	object-fit: cover;

}

.contentLeftContainer {
	width: 100%;
	display: flex;
	flex-direction: row;
	/* 
	@media only screen and (max-width: 768px) {} */
}

.contentRightContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	padding-top: 1.5rem;
}

.tabletIcon {
	display: none;
}

@media only screen and (max-width: 768px) {

	.contentLeftContainer {
		width: 85%;
		flex-direction: column;
	}

	.desktopIcon {
		display: none;
	}

	.tabletIcon {
		display: inline-block;
	}

	.diplomaImg {
		width: 100%;
	}

	.textSm {
		font-size: .8rem !important;
		font-weight: 400 !important;
	}

	.img {
		border-radius: 2rem;
		object-fit: cover;
		width: 100%;
	}

	.contentRightContainer {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 1rem;
		padding: 1.5rem 2rem 0;
	}
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Apply the animation to the element */
.rotatingElement {
    /* Set the duration and specify that it should repeat infinitely */
    animation: rotate 2.5s linear infinite;
}