.active {

	border: black 1px;
}

.link {
	display: block;
	color: gray;
}

.link:hover {
	cursor: pointer;
	color: black;
	border: black 5px !important;
}

.mainContainer {
	padding: 0 9%;
	display: flex;
	flex-direction: column;
}

.universitiesContainer {
	border-radius: 2rem;
}


@media only screen and (max-width: 1080px) {
	.accessModal {
		width: 95% !important;
	}

	.universityItem {
		width: 100% !important;
	}
}

@media only screen and (max-width: 768px) {
	.universityImg {
		width: 100% !important;
	}


	.mobText {
		font-size: 1rem !important;
	}

	.mobMb1 {
		margin-bottom: 1rem !important;
	}

	.mobTextL {
		font-size: 1.5rem !important;
	}

	.mobTextSm {
		font-size: .8rem !important;
	}

	.mobPl2 {
		padding-left: 2rem;
	}

	.mobPl1 {
		padding-left: 1rem;
	}


	.accessModal {
		width: 95% !important;
	}

	.universityItem {
		width: 100% !important;
		display: flex !important;
		flex-direction: column !important;
	}
}
